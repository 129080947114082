/*!
 * ScHero.scss
 *
 * @version 26.07.20, 10:34
 * @since 26.07.20, 10:34
 * @author dev@webserver.ws
 * @copyright © 1997-2020 webserver.ws
 * @license All rights reserved
 * @link https://webserver.ws
 */

.sc-hero-info {
  text-align: center;
  .sc-hero-info-primary-text {
    border-radius: 10px;
    display: inline-block;
    padding: 5px 18px;
    margin: 10px 10px;
    background-color: rgba(255,255,255,0.85);
    border: 1px solid rgba(255,255,255,1);
    box-shadow: 0 0 3px rgba(255,255,255,1);
    color: #ffffff;
  }
  .sc-hero-info-danger-text {
    border-radius: 10px;
    display: inline-block;
    padding: 5px 18px;
    margin: 10px 10px;
    background-color: rgba(255,0,0,0.85);
    color: #ffffff;
    font-size: 30px;
    font-weight: bold;
  }
}

$shadow-hero: 0 0 1px rgba(0,0,0,1);

.sc-p-restaurant-hero {
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /*width: 100vw;*/
  height: 100vh;
  position: relative;
  background-attachment: fixed;
  padding-top: 80px;

  @media only screen and (max-width: 600px) {
    //padding-top: 40vh;
  }

  .sc-hero-text-wrapper {
    text-align: center;

  }

  .sc-hero-text-panel {
    border-radius: 10px;
    display: inline-block;
    padding: 5px 18px;
    margin: 10px 10px;
    //width: 100px;
    //margin: 0 auto;
    //background-image: linear-gradient(to top, rgba(0,180,0,0.6), rgba(0,180,0,0.6));
    background-color: rgba(255,255,255,0.85);
    border: 1px solid rgba(255,255,255,1);
    box-shadow: 0 0 3px rgba(0,0,0,1);
  }

  .sc-hero-text {
    font-size: 36px;
    font-weight: bold;
    color: #ff0000;
    display: block;
    text-align: center;
    text-shadow: 0 0 1px rgba(128,0,0,1);
    margin: 0;
    padding: 0;
  }
  .sc-hero-text2 {
    margin: 12px 0 0 0;
    padding: 0 0 5px 0;
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    display: block;
    text-align: center;
    //text-shadow: $shadow-hero, $shadow-hero, $shadow-hero;
  }
}
