/*!
 * index.scss
 *
 * @version 05.10.20, 16:16
 * @since 26.07.20, 10:34
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

body {
  //margin: 0;
  //font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
  //  'Droid Sans', 'Helvetica Neue', sans-serif;
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
}

code {
  //font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.line-through {
  text-decoration: line-through;
}