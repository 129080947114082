/*!
 * ScToolbox.scss
 *
 * @version 05.10.20, 16:17
 * @since 26.07.20, 10:34
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

.sc-loading-spinner {
  text-align: center;
  color: #888888;
}

.btn-group-fab {
  position: fixed;
  width: 50px;
  height: auto;
  right: 20px; bottom: 20px;
  z-index: 999;
  div {
    position: relative; width: 100%;
    height: auto;
  }
  .btn {
    position: absolute;
    bottom: 0;
    border-radius: 50%;
    display: block;
    //margin-bottom: 4px;
    width: 40px; height: 40px;
    margin: 4px auto;
  }
  .btn-main {
    width: 50px; height: 50px;
    right: 50%; margin-right: -25px;
    z-index: 9;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12);
  }
  .btn-sub {
    bottom: 0; z-index: 8;
    right: 50%;
    margin-right: -20px;
    -webkit-transition: all 2s;
    transition: all 0.5s;

  }
  &.active .btn-sub {
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12);
    &:nth-child(2) {bottom: 60px;}
    &:nth-child(3) {bottom: 110px;}
    &:nth-child(4) {bottom: 160px;}
    &:nth-child(5) {bottom: 210px;}
    &:nth-child(6) {bottom: 260px;}
    &:nth-child(7) {bottom: 310px;}
    &:nth-child(8) {bottom: 360px;}
    &:nth-child(9) {bottom: 410px;}
    &:nth-child(10) {bottom: 460px;}
    &:nth-child(11) {bottom: 510px;}
  }
}

// Runde Buttons
.btn-circle {
  width: 30px !important;
  height: 30px !important;
  text-align: center !important;
  padding: 6px 0 !important;
  font-size: 12px !important;
  line-height: 1.428571429 !important;
  border-radius: 15px !important;
}
.btn-circle.btn-lg {
  width: 50px !important;
  height: 50px !important;
  padding: 10px 16px !important;
  font-size: 18px !important;
  line-height: 1.33 !important;
  border-radius: 25px !important;
}
.btn-circle.btn-xl {
  width: 70px !important;
  height: 70px !important;
  padding: 10px 16px !important;
  font-size: 24px !important;
  line-height: 1.33 !important;
  border-radius: 35px !important;
}

// Hintergrund
.sc-bg-grey-20 {background-color: #cccccc;}

// Logo
.sc-logo {
  color:#888888 !important;
  .sc-logo-part1 {
    font-weight: bold;
    position: relative;
  }
  .sc-logo-part2 {
    position: relative;
    top: -0.02em;
    font-size: 1.4em !important;
    vertical-align: middle;
    color: #ff8800;
    font-weight: normal;
  }
  .sc-logo-part3 {
    color: #888888 !important;
    font-weight: normal;
  }
  .sc-logo-part4 {
    color: #ff8800 !important;
    font-weight: normal;
  }
}

.sc-logo-scm {
  color: #494949 !important;
  .sc-logo-scm-0 {
    //color: #17a2b8 !important;
  }
  .sc-logo-scm-1 {
    color: #ff8800 !important;
  }
  .sc-logo-scm-2 {
    color: #888888 !important;
  }
  .sc-logo-scm-3 {
    //color: #17a2b8 !important;
  }
  sup {
    font-size: 0.7rem !important;
    color: #666666 !important;
  }
}
