/*!
 * ScArrowScrollDown.scss
 *
 * @version 05.10.20, 16:17
 * @since 26.07.20, 10:34
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

.sc-arrow-scroll-down-wrapper {
  position: absolute;
  height: 90px;
  width: 100%;
  bottom: 10px;
  .sc-arrow-scroll-down {
    $ani-speed: 2s;
    $ani-delay: 1s;
    .arrow {
      opacity: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform-origin: 50% 50%;
      transform: translate3d(-50%, -50%, 0);
    }
    .arrow-first {
      animation: arrow-movement $ani-speed ease-in-out infinite;
    }
    .arrow-second {
      animation: arrow-movement $ani-speed $ani-delay ease-in-out infinite;
    }
    .arrow:before,
    .arrow:after {
      background: #fff;
      content: '';
      display: block;
      height: 3px;
      position: absolute;
      top: 0;
      left: -20px;
      width: 50px;
      border: 3px solid white;
    }
    .arrow:before {
      transform: rotate(-45deg) translateX(-29%);
      transform-origin: top left;
    }
    .arrow:after {
      transform: rotate(45deg) translateX(29%);
      transform-origin: top right;
    }
    @keyframes arrow-movement {
      0% {
        opacity: 0;
      }
      70% {
        opacity: 1;
      }
      100% {
        opacity: 0;
        top: 15%;
      }
    }
  }
}