/*!
 * ScSpeisekarte.scss
 *
 * @version 05.10.20, 16:17
 * @since 05.10.20, 16:10
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

@import '../../../style';

.sc-speisekarte-info-button {
  margin: 4px 2px 0 0;
  font-weight: normal;
  font-size: 13px;
  color: $primary;
  cursor: pointer;
}
.sc-speisekarte-info-panel {
  margin-top: 5px;
  margin-bottom: 5px;
  h5 {
    font-size: 13px;
    font-weight: bold;
    color:#444444;
    margin-top: 10px;
    margin-left: 20px;
  }
  ul {
    list-style: none;
    li {
      font-size: 14px;
      color:#666666;
    }
  }
}

//.sc-speisekarte {
  .sc-speisekarte-sup {
    display: inline-block;
    margin: 0 2px 2px 0;
    font-weight: normal;
    font-size: 11px;
    color: #666666;
    &::after {
      content: ', ';
    }
    &:last-child:after {
      content: '';
    }
  }
//}