/*!
 * ScEHighlightsCard.scss
 *
 * @version 05.10.20, 16:17
 * @since 26.07.20, 10:34
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

.sc-e-highlights-card {

  .fa {
    font-size: 32px !important;
  }
}