/*!
 * ScFooter.scss
 *
 * @version 05.10.20, 16:17
 * @since 26.07.20, 10:34
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

.sc-footer {
  text-align: center;
  //background-color: #1d2124;
  color: #888888;
  font-size: 0.9em;
  a {
    color: #888888;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
  }
  .copyright {
    color: #888888;
  }
}

