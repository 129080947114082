/*!
 * App.scss
 *
 * @version 05.10.20, 16:16
 * @since 26.07.20, 10:34
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

h1 {
    font-size: 32px;
    color: #666666;
    margin-top: 30px;
}
h2 {
    font-size: 24px;
    color: #333333;
    margin-top: 30px;
}
h3 {
    font-size: 18px;
    color: #222222;
    margin-top: 0;
    font-weight: bold;
}
