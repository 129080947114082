/*!
 * ScHero.scss
 *
 * @version 05.10.20, 16:17
 * @since 26.07.20, 10:34
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

.sc-hero-info {
  text-align: center;
  .sc-hero-info-primary-text {
    border-radius: 10px;
    display: inline-block;
    padding: 5px 18px;
    margin: 10px 10px;
    background-color: rgba(77,153,108,0.85);
    border: 1px solid rgba(77,153,108,1);
    box-shadow: 0 0 3px rgba(77,153,108,1);
    color: #ffffff;
  }
  .sc-hero-info-danger-text {
    border-radius: 10px;
    display: inline-block;
    padding: 5px 18px;
    margin: 10px 10px;
    background-color: rgba(255,0,0,0.85);
    color: #ffffff;
    font-size: 30px;
    font-weight: bold;
  }
}
