/*!
 * ScEStellplaetzeWohnmobile.scss
 *
 * @version 05.10.20, 16:17
 * @since 26.07.20, 10:34
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

.sc-e-stellplaetze-wohnmobile {
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /*width: 100vw;*/
  height: 100vh;
  position: relative;
  background-attachment: fixed;
  color: white;
  margin-left: 0;
  margin-right: 0;
  text-shadow: 0 0 5px #000000;
  h2 {
    color: white;
    font-size: 40px;
  }
}