/*!
 * ScTextbox.scss
 *
 * @version 05.10.20, 16:17
 * @since 26.07.20, 10:34
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

.sc-textbox {
  h1 {
    color: #222222;
  }
}

.sc-textbox-center {
  text-align: center;
  p {
    margin: 0 0 10px 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.sc-textbox-danger{
   border-radius: 10px;
   display: inline-block;
   padding: 5px 18px;
   margin: 10px 10px;
   background-color: rgba(255,0,0,0.85);
   color: #ffffff;
   font-size: 30px;
   font-weight: bold;
 }