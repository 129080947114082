/*!
 * ScEMietunterkunftTile.scss
 *
 * @version 05.10.20, 16:17
 * @since 26.07.20, 10:34
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

.sc-e-mietunterkunft-tile {

  $sc-tile-thumbnail-width: 100%;
  $sc-tile-thumbnail-height: 288px;

  .thumbnail {
    width: $sc-tile-thumbnail-width;
    height: $sc-tile-thumbnail-height;
    object-fit: cover;
  }

  .sc-e-mietunterkunft-tile-e {
    .badge {
      margin-left: 3px;
      margin-bottom: 3px;
    }
  }
}

