/*!
 * ScContentHtml.scss
 *
 * @version 06.08.20, 21:00
 * @since 06.08.20, 21:00
 * @author dev@webserver.ws
 * @copyright © 1997-2020 webserver.ws
 * @license All rights reserved
 * @link https://webserver.ws
 */

.sc-content-md {
  h1 {
    padding-top: 32px;
  }
}

.sc-content-md-p-center {
  p  {
    text-align: center;
  }
}