/*!
 * ScPRestaurantHero.scss
 *
 * @version 05.10.20, 16:17
 * @since 05.10.20, 16:12
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

@import "../../../style";

$shadow-hero: 0 0 1px rgba(77,153,108,1);

.sc-p-restaurant-hero {
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /*width: 100vw;*/
    height: 100vh;
    position: relative;
    background-attachment: fixed;
    padding-top: 80px;

    @media only screen and (max-width: 600px) {
        //padding-top: 40vh;
    }

    .sc-hero-text-wrapper {
        text-align: center;

    }

    .sc-hero-info-wrapper {
        text-align: center;
        .sc-hero-info-panel {
            border-radius: 10px;
            display: inline-block;
            padding: 5px 18px;
            margin: 10px 10px;
            background-color: rgba(255,0,0,0.85);
            color: #ffffff;
            font-size: 30px;
            font-weight: bold;
        }
    }

    .sc-hero-text-panel {
        border-radius: 10px;
        display: inline-block;
        padding: 5px 18px;
        margin: 10px 10px;
        //width: 100px;
        //margin: 0 auto;
        //background-image: linear-gradient(to top, rgba(0,180,0,0.6), rgba(0,180,0,0.6));
        background-color: rgba(77,153,108,0.85);
        border: 1px solid rgba(77,153,108,1);
        box-shadow: 0 0 3px rgba(77,153,108,1);
    }

    .sc-hero-text {
        font-size: 36px;
        color: #ffffff;
        display: block;
        text-align: center;
        text-shadow: $shadow-hero, $shadow-hero, $shadow-hero;
        margin: 0;
        padding: 0;
    }
    .sc-hero-text2 {
        margin: 0;
        padding: 0 0 5px 0;
        font-size: 20px;
        color: #ffffff;
        display: block;
        text-align: center;
        text-shadow: $shadow-hero, $shadow-hero, $shadow-hero;
    }
}