/*!
 * ScEHighlights.scss
 *
 * @version 05.10.20, 16:17
 * @since 26.07.20, 10:34
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

.sc-e-highlights {
  .sc-e-highlights-card {
    //box-sizing: border-box;
    //margin: 5px;
    //width: 75% !important;
  }
  .fa {
    font-size: 32px !important;
  }
}
